import { SchemaType } from "./advanced-flow/schemas/schemas";
import {
  ButtonHierarchy,
  ButtonStyleDefinition,
  Enableable,
  Override,
} from "./brand-kit";
import { DiscountGroupType, DiscountProvider } from "./discount";
import { UtmParameters } from "./utm";
export { widthPixelsToPercentage } from "./email-builder/email-sizing";

// TODO: delete me when Apple interactive emails are ready to go live
export const APPLE_MAIL_ENABLED = true;

// TODO: delete me when Scratch To Reveal block is ready to go live
export const SCRATCH_TO_REVEAL_ENABLED = true;

// Josh - I have no idea what any of this is doing.
// I extracted this from the customer return generation process
// and inferred the types from the context.
export interface CustomerReturnCreatedAttachmentGenerationParameters {
  shipments: {
    postage_label?: string;
    form_label?: string;
    _shipment: { forms?: { form_type?: string; form_url?: string }[] };
  }[];
  variables: {
    postage_label?: string;
    form_label?: string;
    commercialInvoice?: string;
  };
  pickup_details: boolean; // all we care about is truthiness, apparently
}

/**
 * Emails can contain multiple bodies of content, of the following types
 */
export enum EmailFormat {
  /**
   * Standard HTML email
   */
  STATIC = "static",
  /**
   * AMP email
   */
  AMP = "amp",
  // Apple Mail should NOT be added here.
  // It should instead be rendered inside of static emails, conditionally rendered via @supports queries
}

/**
 * Different email clients have differing levels of interactivity.
 * This is different from EmailFormat because both FALLBACK and APPLE_MAIL
 * interactivity take place in a "static" email, but rendered in different clients.
 */
export enum InteractivityContext {
  FALLBACK = "fallback",
  AMP = "amp",
  APPLE_MAIL = "apple-mail",
}

export enum ProductSelectionType {
  DYNAMIC = "dynamic",
  MANUAL = "static",
}

export enum CartLayoutType {
  ROWS = "rows",
  COLUMNS = "columns",
}

export enum ShoppableProductsSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  EXTRA_LARGE = "xl",
}

export namespace ButtonSize {
  export const SMALL = 0.8;
  export const MEDIUM = 1;
  export const LARGE = 1.2;
  export const DEFAULT_HEIGHT = 40;
  export const DEFAULT_WIDTH = 200;
}

export namespace PaddingSize {
  export const SMALL = 8;
  export const MEDIUM = 16;
  export const LARGE = 32;
}

export enum FontWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export type Padding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export enum TemplateType {
  TRANSACTIONAL = "transactional",
  MARKETING = "marketing",
  DEFAULT = "default",
}

export interface Discount {
  _id: string;
  title: string;
  provider: DiscountProvider;
  groupType: DiscountGroupType;
  discountId: string;
}

/**
 * Templates are nested inside of various other objects
 */
export enum TemplateSource {
  TEMPLATE_COLLECTION = "template_collection",
  CAMPAIGN = "campaign",
  // AUTOMATION might go here in the future
}

export function templateTypeLabel(templateType: TemplateType): string {
  switch (templateType) {
    case TemplateType.TRANSACTIONAL:
      return "Transactional";
    case TemplateType.MARKETING:
      return "Marketing";
    case TemplateType.DEFAULT:
      return "Default";
  }
}

export enum Alignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export const alignmentToFlexAlign: Record<Alignment, string> = {
  [Alignment.LEFT]: "flex-start",
  [Alignment.CENTER]: "center",
  [Alignment.RIGHT]: "flex-end",
};

export enum VerticalAlignment {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom",
}

export enum EmailHeaderType {
  IMAGE = "image",
  LOGO = "logo",
  TEXT = "text",
}

export enum Size {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  CUSTOM = "custom",
}

export enum ButtonLinkType {
  WEB_PAGE = "web-page",
  DYNAMIC_VARIABLE = "dynamic-variable",
}

export type Address = {
  businessAddress: string;
  legalAddress: string;
  cityStateZip: string;
  country: string;
};

export enum FontFamily {
  ARIAL = "Arial",
  COURIER_NEW = "Courier New",
  GEORGIA = "Georgia",
  LUCIDA_SANS_UNICODE = "Lucida Sans Unicode",
  TAHOMA = "Tahoma",
  TIMES_NEW_ROMAN = "Times New Roman",
  TREBUCHET_MS = "Trebuchet MS",
  VERDANA = "Verdana",
}

export const FONT_FAMILIES = [
  FontFamily.ARIAL,
  FontFamily.COURIER_NEW,
  FontFamily.GEORGIA,
  FontFamily.LUCIDA_SANS_UNICODE,
  FontFamily.TAHOMA,
  FontFamily.TIMES_NEW_ROMAN,
  FontFamily.TREBUCHET_MS,
  FontFamily.VERDANA,
] as const;

export enum EmailBlockType {
  TRACKABLE_SUMMARY = "order-summary",
  TRACKING_INFO = "tracking-info",
  BUTTON = "button",
  PRODUCTS = "interactive-cart", // value is for backwards compatibility
  HEADER = "header",
  FOOTER = "footer",
  IMAGE = "image",
  TEXT = "text",
  SPACER = "spacer",
  LINE = "line",
  REVIEW_REQUEST = "review-request",
  COLUMN = "column",
  MENU = "menu",
  SOCIALS = "socials",
  DISCOUNT = "discount",
  /**
   * V2 of the interactive cart. Intended to look way flashier and work in both AMP and Apple Mail.
   * Intentionally fully decoupled from V1.
   */
  SHOPPABLE_PRODUCTS = "shoppable-products",
  /**
   * Interactive scratch-to-reveal element in email. Recipients can scratch to reveal hidden content.
   */
  SCRATCH_TO_REVEAL = "scratch-to-reveal",
}

export enum SocialPlatform {
  APPLE = "apple",
  DISCORD = "discord",
  FACEBOOK = "facebook",
  GITHUB = "github",
  GOOGLE = "google",
  INSTAGRAM = "instagram",
  LINKEDIN = "linkedin",
  PINTEREST = "pinterest",
  REDDIT = "reddit",
  SNAPCHAT = "snapchat",
  TIKTOK = "tiktok",
  TWITTER = "twitter",
  YOUTUBE = "youtube",
}

export type MenuItem = { id: string; label: string };

export type SocialItem = { id: string; platform: SocialPlatform; url: string };
export namespace Section {
  interface BaseSection {
    schemaFieldName?: string;
    blockId?: string;
    duplicate?: (section: Section | undefined) => Section;
    sectionPadding: Padding;
    sectionColor: string;
  }

  export interface ScratchToReveal extends BaseSection {
    type: EmailBlockType.SCRATCH_TO_REVEAL;
    discountId?: string;
    alignment: Alignment;
    cornerRadiusPx: number;
    fontFamily: FontFamily;
    fontWeight: FontWeight;
    fontSizePx: number;
    textColor: string;
    secretBackgroundColor: string;
    scratchOffColor: string;
    scratcherSize: ScratcherSize;
    scratchOffAreaPadding: Padding;
    fallbackText: string;
  }

  export interface Discount extends BaseSection {
    type: EmailBlockType.DISCOUNT;
    discountId?: string;
    alignment: Alignment;
    fontFamily: FontFamily;
    fontWeight: FontWeight;
    fontSize: number;
    textColor: string;
    blockBackgroundColor: string;
  }

  export interface Column extends BaseSection {
    type: EmailBlockType.COLUMN;
    columns: (SectionWithoutColumn | null)[];
    columnCount: number;
    gap: number;
    stackOnMobile: boolean;
    alignment: VerticalAlignment;
  }

  export interface Menu extends BaseSection {
    type: EmailBlockType.MENU;
    menuItems: MenuItem[];
    alignment: Alignment;
    fontFamily: FontFamily;
    fontSize: number;
    linkColor: string;
    textColor: string;
    stackOnMobile: boolean;
  }

  export enum SocialIconColors {
    BLACK = "black",
    WHITE = "white",
    GRAY = "gray",
  }

  export interface Socials extends BaseSection {
    type: EmailBlockType.SOCIALS;
    socialLinks: SocialItem[];
    iconColor: SocialIconColors;
    iconPadding: number;
    alignment: Alignment;
  }

  export interface Header extends BaseSection {
    type: EmailBlockType.HEADER;
    headerType: EmailHeaderType;
    layout: Alignment;
    imageUrl: string;
    text: string;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    logoHeight: number;
    imageHeight: number;
    clickthroughUrl?: string;
    altText?: string;
  }

  export interface Footer extends BaseSection {
    type: EmailBlockType.FOOTER;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    textColor: string;
    alignment: Alignment;
    fontSize?: number;
    fontFamily?: FontFamily;
  }

  export interface Text extends BaseSection {
    type: EmailBlockType.TEXT;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    linkColor: string;
    text: string;
  }

  export interface Button extends BaseSection {
    type: EmailBlockType.BUTTON;
    /** @deprecated Use padding instead */
    width: number;
    /** @deprecated Use padding instead */
    height: number;
    /** @deprecated Use padding instead */
    size: Size;
    alignment: Alignment;
    cornerRadius: number;
    buttonText: string;
    /** @deprecated Use padding instead */
    horizontalPadding: Size;
    /** @deprecated Use padding instead */
    verticalPadding: Size;
    padding: Padding;
    /** hardcoded button link as opposed to dynamic variable from schema */
    buttonLink?: string;
    fillColor: string;
    strokeColor: string;
    textColor: string;
    strokeWeight: number;
    fontFamily: FontFamily;
    fontSize: number;
    as?: "button" | "a";
    buttonType?: "button" | "submit" | "reset";
    fullWidth?: boolean;
    linkType?: ButtonLinkType;
  }

  export interface ReviewRequest extends BaseSection {
    type: EmailBlockType.REVIEW_REQUEST;
    sectionHeader: string;
    imageCornerRadius: number;
    imageSize: Size;
    buttonHeight: number;
    buttonWidth: number;
    buttonColor: string;
    buttonTextColor: string;
    buttonSize: Size;
    buttonCornerRadius: number;
    textColor: string;
    fontFamily: FontFamily;
    imageAspectRatio: number;
  }

  export interface Line extends BaseSection {
    type: EmailBlockType.LINE;
    color: string;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
  }

  export interface Spacer extends BaseSection {
    type: EmailBlockType.SPACER;
    height: number;
  }

  export interface Image extends BaseSection {
    type: EmailBlockType.IMAGE;
    imageUrl: string;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    showCaption: boolean;
    caption?: string;
    altText?: string;
    clickthroughUrl?: string;
  }

  export interface TrackingInfo extends BaseSection {
    type: EmailBlockType.TRACKING_INFO;
    textColor: string;
    buttonVisible: boolean;
    buttonHeight: number;
    buttonWidth: number;
    buttonColor: string;
    buttonTextColor: string;
    buttonSize: Size;
    buttonCornerRadius: number;
    statusIndicatorColor: string;
    statusIndicatorInnerColor: string;
    fontFamily: FontFamily;
  }

  export interface TrackableSummary extends BaseSection {
    type: EmailBlockType.TRACKABLE_SUMMARY;
    showOrderInformation: boolean;
    showCostSummary: boolean;
    showPaymentInformation: boolean;
    showCustomerInformation: boolean;
    textColor: string;
    fontFamily: FontFamily;
    imageSize: Size;
    imageCornerRadius: number;
    imageAspectRatio: number;
  }

  type InlineButton = Omit<Button, "sectionColor" | "sectionPadding" | "type">;

  // TODO: don't extend UpsellProductSection. Instead, give it a productSelectionStrategies: UpsellProductSection[] field
  // or something along those lines.
  export interface Products extends BaseSection {
    type: EmailBlockType.PRODUCTS;
    textColor: string;
    fontFamily: FontFamily;
    imageCornerRadius: number;
    checkoutButton: InlineButton; // TODO: Once email templates has a repo using zod this will throw an error, this fixes it for campaigns
    lineItemButtons: InlineButton; // We should write a migration to add sectionPadding to these sub button schema
    /**
     * Only used when productSelectionType is dynamic,
     * otherwise it's inferred by the manually-specified products.
     */
    numberOfProducts: number;
    imageSize: Size;
    /**
     * The high-level product selection strategy: manually-specified or dynamic products
     */
    productSelectionType: ProductSelectionType;
    showImage?: boolean;
    showTitle?: boolean;
    showPrice?: boolean;
    showButton?: boolean;
    showQuantity?: boolean;
    collectionId?: string;
    metafield?: { key: string; namespace: string };
    /**
     * column layout with 1 column is different from rows layout.
     * Column layout with 1 column makes the product image take up the full width of the email.
     */
    layoutType?: CartLayoutType;
    /**
     * Controls whether the text, buttons, etc. of a line item are aligned to the left, center, or right.
     * Only used when layoutType is column.
     */
    alignment: Alignment;
    /**
     * Only used when layoutType is column, although required
     * so that its value is preserved if layoutType is changed later.
     */
    columns: number;
    stackOnMobile: boolean;
    manuallySelectedProducts: ManuallySelectedProduct[];
    recommendedProductFilterId?: string;
    imageAspectRatio: number;
  }

  export interface ShoppableProducts extends BaseSection {
    type: EmailBlockType.SHOPPABLE_PRODUCTS;
    /**
     * The high-level product selection strategy: manually-specified or dynamic products
     */
    productSelectionType: ProductSelectionType;
    manuallySelectedProducts: ManuallySelectedProduct[];
    productFilterId?: string;
    numberOfDynamicProducts: number;
    cardCornerRadiusPx: number;
    size: ShoppableProductsSize;
    show: {
      image: boolean;
      title: boolean;
      price: boolean;
      description: boolean;
      button: boolean;
    };
    fontFamily: FontFamily;
    textColor: string;
    alignment: Alignment;
    productButtons: BrandKitEmailButton;
    checkoutButton: BrandKitEmailButton;
    imageAspectRatio: number;
  }
}

export type SectionWithoutColumn =
  | Section.Header
  | Section.Footer
  | Section.Text
  | Section.Button
  | Section.Line
  | Section.Spacer
  | Section.Image
  | Section.TrackingInfo
  | Section.TrackableSummary
  | Section.Products
  | Section.ReviewRequest
  | Section.Menu
  | Section.Socials
  | Section.Discount
  | Section.ShoppableProducts
  | Section.ScratchToReveal;

export type Section = SectionWithoutColumn | Section.Column;

export const getButtonSize = (
  size: Size,
  customWidth: number,
  customHeight: number,
) => {
  switch (size) {
    case Size.SMALL:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.SMALL,
      };
    case Size.MEDIUM:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.MEDIUM,
      };
    case Size.LARGE:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.LARGE,
      };
    case Size.CUSTOM:
      return { width: customWidth, height: customHeight };
  }
};

export const getPadding = (
  horizontalPadding: Size,
  verticalPadding: Size,
  customPadding: Padding,
) => {
  let top = customPadding.top,
    right = customPadding.right,
    bottom = customPadding.bottom,
    left = customPadding.left;
  switch (horizontalPadding) {
    case Size.SMALL:
      left = right = PaddingSize.SMALL;
      break;
    case Size.MEDIUM:
      left = right = PaddingSize.MEDIUM;
      break;
    case Size.LARGE:
      left = right = PaddingSize.LARGE;
      break;
  }
  switch (verticalPadding) {
    case Size.SMALL:
      top = bottom = PaddingSize.SMALL;
      break;
    case Size.MEDIUM:
      top = bottom = PaddingSize.MEDIUM;
      break;
    case Size.LARGE:
      top = bottom = PaddingSize.LARGE;
      break;
  }
  return { top, right, bottom, left };
};

export class EmailUtmParameters extends UtmParameters {
  constructor(campaign?: string, contact?: string) {
    super({ source: "redo", medium: "email", campaign, contact });
  }

  override getSource(): string {
    return super.getSource() ?? "";
  }

  override getMedium(): string {
    return super.getMedium() ?? "";
  }
}

export enum ScreenSize {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

/**
 * How big the gap is between columns in a column section.
 * TODO: make this a setting in the column section itself.
 */
export const COLUMN_GAP = 24;

export interface BrandKitEmailButton {
  hierarchy: ButtonHierarchy;
  override: Enableable<Override<ButtonStyleDefinition>>;
  fullWidth: boolean;
  text: string;
}

/**
 * Whether an email block is "interactive" in the sense that it has advanced functionality in AMP and Apple Mail.
 *
 * Used to determine whether to render an AMP version of an email, for example.
 */
export const emailBlockIsInteractive: Record<EmailBlockType, boolean> = {
  [EmailBlockType.SHOPPABLE_PRODUCTS]: true,
  [EmailBlockType.PRODUCTS]: true,
  [EmailBlockType.BUTTON]: false,
  [EmailBlockType.REVIEW_REQUEST]: false,
  [EmailBlockType.TRACKABLE_SUMMARY]: false,
  [EmailBlockType.TRACKING_INFO]: false,
  [EmailBlockType.IMAGE]: false,
  [EmailBlockType.TEXT]: false,
  [EmailBlockType.SPACER]: false,
  [EmailBlockType.LINE]: false,
  [EmailBlockType.HEADER]: false,
  [EmailBlockType.FOOTER]: false,
  [EmailBlockType.MENU]: false,
  [EmailBlockType.SOCIALS]: false,
  [EmailBlockType.DISCOUNT]: false,
  [EmailBlockType.COLUMN]: false,
  [EmailBlockType.SCRATCH_TO_REVEAL]: true,
};

export namespace AppleMailConstants {
  export const RECOMMENDATIONS_CACHE_DURATION_HOURS = 1;
  export const RECOMMENDATIONS_CACHE_KEY_PREFIX = "email-recommendations-";
  export const MAX_QUANTITY_PER_PRODUCT = 10;
  export const MAX_PRODUCTS_PER_EMAIL = 10;
  export const MAX_VARIANT_DIMENSIONS = 3; // shopify-specific
  /**
   * Ideally this would be max number of rows of text, but CSS line-clamp is not yet supported by Apple Mail (or browsers, for that matter)
   */
  export const MAX_TITLE_LENGTH = 70;
  export const MAX_DESCRIPTION_LENGTH = 80;
}

/**
 * https://github.com/ampproject/amphtml/issues/40251
 *
 * `true` means the bug exists
 */
export const AMP_IGNORES_HEIGHTS = true;

export interface ManuallySelectedProduct {
  productId: string;
  variantId: string;
}

export const schemasWithPreexistingCart = [
  SchemaType.MARKETING_CHECKOUT_ABANDONMENT,
  // SchemaType.MARKETING_BROWSE_ABANDONMENT, // shopify does not provide cart data for browse abandonment
  SchemaType.MARKETING_CART_ABANDONMENT,
];

export enum ScratcherSize {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  EXTRA_LARGE = "xl",
}
