import { useHandler } from "@redotech/react-util/hook";
import * as classnames from "classnames";
import {
  ForwardedRef,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  TouchEventHandler,
  forwardRef,
  memo,
} from "react";
import * as buttonCss from "./button.module.css";
import CircleSpinner from "./circle-spinner.svg";
import ChevronDown from "./icon-old/chevron-down.svg";
import { StyleProps, styleClasses, stylePropKeys } from "./styles";
import { Tooltip } from "./tooltip/tooltip";

export namespace ButtonTheme {
  export const BRAND = Symbol("Brand");
  export const DANGER = Symbol("Danger");
  export const DANGER_OUTLINED = Symbol("Danger Outlined");
  export const GHOST = Symbol("Ghost");
  export const OUTLINED = Symbol("Outlined");
  export const PRIMARY = Symbol("Primary");
  export const SOLID_LIGHT = Symbol("Solid Light");
  export const SOLID_LIGHT_BRAND = Symbol("Solid Light Brand");
  export const WARNING = Symbol("Warning");
  export const DARK = Symbol("Dark");
}

export type ButtonTheme =
  | typeof ButtonTheme.BRAND
  | typeof ButtonTheme.DANGER
  | typeof ButtonTheme.DANGER_OUTLINED
  | typeof ButtonTheme.GHOST
  | typeof ButtonTheme.OUTLINED
  | typeof ButtonTheme.PRIMARY
  | typeof ButtonTheme.SOLID_LIGHT
  | typeof ButtonTheme.SOLID_LIGHT_BRAND
  | typeof ButtonTheme.WARNING
  | typeof ButtonTheme.DARK;

export namespace ButtonSize {
  export const LARGE = Symbol("large");
  export const MEDIUM = Symbol("normal");
  export const SMALL = Symbol("compact");
  export const EXTRA_SMALL = Symbol("extra-small");
  export const MICRO = Symbol("micro");
  export const WIDE_NANO = Symbol("wide-nano");
  export const NANO = Symbol("nano");
}

export type ButtonSize =
  | typeof ButtonSize.LARGE
  | typeof ButtonSize.MEDIUM
  | typeof ButtonSize.SMALL
  | typeof ButtonSize.EXTRA_SMALL
  | typeof ButtonSize.MICRO
  | typeof ButtonSize.WIDE_NANO
  | typeof ButtonSize.NANO;

export namespace ButtonBorder {
  export const DARK = Symbol("dark");
  export const LIGHT = Symbol("light");
  export const TRANSPARENT = Symbol("transparent");
}

export type ButtonBorder =
  | typeof ButtonBorder.DARK
  | typeof ButtonBorder.LIGHT
  | typeof ButtonBorder.TRANSPARENT;

const themeClass = {
  [ButtonTheme.BRAND]: buttonCss.brand,
  [ButtonTheme.DANGER]: buttonCss.danger,
  [ButtonTheme.DANGER_OUTLINED]: buttonCss.dangerOutlined,
  [ButtonTheme.GHOST]: buttonCss.ghost,
  [ButtonTheme.OUTLINED]: buttonCss.outlined,
  [ButtonTheme.PRIMARY]: buttonCss.primary,
  [ButtonTheme.SOLID_LIGHT]: buttonCss.solidLight,
  [ButtonTheme.SOLID_LIGHT_BRAND]: buttonCss.solidLightBrand,
  [ButtonTheme.WARNING]: buttonCss.warning,
  [ButtonTheme.DARK]: buttonCss.darkTheme,
};

const sizeClass = {
  [ButtonSize.LARGE]: buttonCss.large,
  [ButtonSize.MEDIUM]: buttonCss.medium,
  [ButtonSize.SMALL]: buttonCss.small,
  [ButtonSize.MICRO]: buttonCss.micro,
  [ButtonSize.EXTRA_SMALL]: buttonCss.extraSmall,
  [ButtonSize.WIDE_NANO]: buttonCss.wideNano,
  [ButtonSize.NANO]: buttonCss.nano,
};

const borderClass = {
  [ButtonBorder.DARK]: buttonCss.dark,
  [ButtonBorder.LIGHT]: buttonCss.light,
  [ButtonBorder.TRANSPARENT]: buttonCss.transparent,
};

type ButtonProps = {
  children?: ReactNode;
  className?: string;
  form?: string;
  icon?: ({ className }: { className?: string }) => ReactElement;
  iconAlign?: "left" | "right";
  theme?: ButtonTheme;
  disabled?: boolean;
  pending?: boolean;
  size?: ButtonSize;
  fullWidth?: boolean;
  border?: ButtonBorder;
  style?: any;
  chevron?: boolean;
  tooltip?: string;
  type?: "button" | "reset" | "submit";
  dataTarget?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: TouchEventHandler<HTMLButtonElement>;
  onPointerUp?: MouseEventHandler<HTMLButtonElement>;
} & StyleProps;

/**
 * @deprecated use RedoButton instead
 */
export const Button = memo(
  forwardRef(function Button(
    {
      children,
      className,
      form,
      icon,
      iconAlign = "left",
      theme = ButtonTheme.GHOST,
      disabled = false,
      onClick,
      pending = false,
      size = ButtonSize.MEDIUM,
      fullWidth = false,
      border = ButtonBorder.DARK,
      type = "button",
      style = undefined,
      chevron = false,
      tooltip,
      dataTarget = undefined,
      onTouchStart,
      onPointerUp,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const buttonClassName = classnames(
      buttonCss.button,
      themeClass[theme],
      sizeClass[size],
      borderClass[border],
      className,
      { [buttonCss.pending]: pending },
    );

    // TODO: many of these styleProps, like padding, are overridden by old button styles. Remove those
    const styleProps = Object.fromEntries(
      Object.entries(rest).filter(([key]) => stylePropKeys.has(key as any)),
    );
    const otherProps = Object.fromEntries(
      Object.entries(rest).filter(([key]) => !stylePropKeys.has(key as any)),
    );

    const button = (
      <button
        className={classnames(
          buttonClassName,
          styleClasses(styleProps),
          fullWidth && buttonCss.fullWidth,
        )}
        disabled={disabled || pending}
        form={form}
        onClick={onClick}
        ref={ref}
        style={style}
        type={type}
        {...otherProps}
        data-target={dataTarget}
        onPointerUp={onPointerUp}
        onTouchStart={onTouchStart}
      >
        {pending && <CircleSpinner className={buttonCss.spinner} />}
        <div className={buttonCss.content}>
          {icon &&
            iconAlign === "left" &&
            icon({ className: classnames(buttonCss.icon, buttonCss.iconLeft) })}
          {chevron ? (
            <div className={buttonCss.chevronButton}>
              {children}
              <ChevronDown className={buttonCss.chevron} />
            </div>
          ) : (
            children
          )}
          {icon &&
            iconAlign === "right" &&
            icon({
              className: classnames(buttonCss.icon, buttonCss.iconRight),
            })}
        </div>
      </button>
    );

    return tooltip ? (
      <Tooltip title={tooltip}>
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  }),
);

export enum IconButtonTheme {
  LIGHT = 0,
  DARK = 1,
  GRAY = 2,
  DANGER = 3,
}

export enum Border {
  DARK = 0,
  LIGHT = 1,
  TRANSPARENT = 2,
}

export const IconButton = memo(
  forwardRef(function IconButton(
    {
      children,
      disabled = false,
      onClick,
      size = ButtonSize.MEDIUM,
      theme = IconButtonTheme.DARK,
      type = "button",
      iconColor,
      iconBackgroundColor,
      border = Border.TRANSPARENT,
      ...props
    }: {
      children: ReactNode;
      onClick?(event?: React.MouseEvent): void;
      size?: typeof ButtonSize.MEDIUM | typeof ButtonSize.SMALL; // IconButton only supports medium and small sizes
      theme?: IconButtonTheme;
      iconColor?: string;
      iconBackgroundColor?: string;
      border?: Border;
    } & StyleProps &
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const handleClick: MouseEventHandler = useHandler((event) => {
      onClick && onClick();
      event.stopPropagation();
    });
    const baseClasses = [
      buttonCss.iconButton,
      {
        [buttonCss.dark]: theme === IconButtonTheme.DARK,
        [buttonCss.light]: theme === IconButtonTheme.LIGHT,
        [buttonCss.gray]: theme === IconButtonTheme.GRAY,
        [buttonCss.danger]: theme === IconButtonTheme.DANGER,
        [buttonCss.iconButtonBorder]: border !== Border.TRANSPARENT,
        [buttonCss.iconButtonBorderDark]: border === Border.DARK,
        [buttonCss.iconButtonBorderLight]: border === Border.LIGHT,
      },
      sizeClass[size],
    ];
    const classNames = props.className
      ? classnames(...baseClasses, props.className)
      : classnames(...baseClasses);

    const styleProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => stylePropKeys.has(key as any)),
    );
    const otherProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !stylePropKeys.has(key as any)),
    );
    const style: any = {};
    if (iconColor) {
      style.color = iconColor;
    }
    if (iconBackgroundColor) {
      style.backgroundColor = iconBackgroundColor;
    }

    return (
      <button
        className={classnames(styleClasses(styleProps), classNames)}
        disabled={disabled}
        onClick={handleClick}
        ref={ref}
        style={style}
        type={type}
        {...otherProps}
      >
        {children}
      </button>
    );
  }),
);
